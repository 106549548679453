// SplashPage.vue
<template>
  <div class="splash-container">
    <!-- Navigation -->
    <nav
      class="navbar navbar-expand-lg"
      style="margin-top:2cm;"
    >
      <div class="container d-flex justify-content-center">
        <a
          class="navbar-brand"
          href="#"
        >
          <img
            :src="require('@/assets/images/logo/e2i/e2i_Logo2White_updated.png')"
            alt="UXE2i Logo"
            class="nav-logo"
          >
        </a>
      </div>
    </nav>

    <!-- Main Content -->
    <div class="content-wrapper">
      <div class="container">
        <div
          class="row align-items-center"
          style="margin-top: 0.5cm;"
        >
          <div class="hero-content mx-auto text-center">
            <h1
              class="tagline"
              style="margin-bottom: 5px;"
            >
              Ready to Propel Your Career?
            </h1>
            <hr style="background-color: white;margin-bottom: 10px">
            <p class="subtitle">
              Start Your Journey Here
            </p>
            <button
              class="find-job-btn"
              @click="$router.push('kiosk/events/308c7aad-38ad-4b02-88dd-f934ef1128ca_g')"
            >
              Launch Job Search
              
            </button>

          </div>
        </div>

        <div
          class="row "
          style="height: 40cm; margin-top: -14cm;"
        >
          <img
            :src="require('@/assets/images/rocket-clouds.svg')"
            style="height: 52cm;width: 100%;"
            class="mx-auto"
            @click="$router.push('kiosk/events/308c7aad-38ad-4b02-88dd-f934ef1128ca_g')"
          >
          <!-- <LottieAnimation
            ref="anim"
            class="mx-auto"
            :loop="true"
            style="height: 20cm"
            :animationData="require('@/assets/images/rocket.json')"
          /> -->
        </div>

      </div>
    </div>

    <!-- Floating Elements -->
    <div class="floating-elements">
      <div class="element element-1" />
      <div class="element element-2" />
      <div class="element element-3" />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'SplashPage',
  components: {
  },
  created() {
    const sessionID = localStorage.getItem('session_id') || null
    if (!sessionID) {
      localStorage.setItem('session_id', uuidv4().toString())
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.splash-container {
  min-height: 100vh;
  background: linear-gradient(135deg, rgba(255, 107, 69, 0.95) 0%, rgba(255, 211, 0, 0.95) 100%);
  position: relative;
  overflow: hidden;
}

/* Navigation Styles */
.navbar {
  padding: 1.5rem 0;
  position: relative;
  z-index: 10;
  backdrop-filter: blur(10px);
}

.nav-logo {
  height: 140px;
  width: 100%;
}

.navbar-nav .nav-link {
  color: white;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  position: relative;
  transition: all 0.3s ease;
}

.navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.navbar-nav .nav-link:hover::after,
.navbar-nav .nav-link.active::after {
  width: 80%;
}

/* Main Content Styles */
.content-wrapper {
  min-height: calc(60vh - 30px);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 4rem 0;
}

.hero-content {
  padding-right: 2rem;
}

.main-logo {
  max-width: 300px;
  height: auto;
  margin-bottom: 2rem;
}

.tagline {
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subtitle {
  color: white;
  font-size: 2.4rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  opacity: 0.9;
}

.find-job-btn {
  background: white;
  color: #ff6b45;
  border: none;
  padding: 1.2rem 3rem;
  border-radius: 50px;
  font-size: 2.0rem;
  margin-top: 3.2rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.find-job-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  background: #ff6b45;
  color: white;
}

.find-job-btn i {
  width: 20px;
  height: 20px;
}

/* Illustration Styles */
.illustration-container {
  position: relative;
  z-index: 2;
}

.job-illustration {
  width: 100%;
  height: 800px;
  animation: float 6s ease-in-out infinite;
}

/* Floating Elements */
.floating-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.element {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.element-1 {
  width: 500px;
  height: 500px;
  top: 10%;
  right: 5%;
  animation: float 15s infinite linear;
}

.element-2 {
  width: 200px;
  height: 200px;
  bottom: 10%;
  left: 5%;
  animation: float 12s infinite linear reverse;
}

.element-3 {
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  animation: float 18s infinite linear;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Responsive Design */
/* @media (max-width: 991px) {
  .hero-content {
    text-align: center;
    padding-right: 0;
    margin-bottom: 3rem;
  }

  .main-logo {
    margin: 0 auto 2rem;
  }

  .illustration-container {
    text-align: center;
  }

  .job-illustration {
    max-width: 700px;
  }

  .tagline {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }
} */

@media (max-width: 768px) {
  .navbar-collapse {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 1rem;
    border-radius: 10px;
    margin-top: 1rem;
  }

  .job-illustration {
    max-width: 700px;
  }
}

/* Rocket Container */
.rocket-container {
  position: relative;
  text-align: center;
  width: 300px;
  height: 800px;
}

.rocket {
  position: absolute;
  bottom: 0;
  animation: blastoff 5s infinite;
}

.rocket-image {
  width: 150px;
  height: auto;
}

/* Flame Animation */
.flames {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flame {
  width: 15px;
  height: 40px;
  background: radial-gradient(circle, rgba(255, 69, 0, 0.9) 0%, rgba(255, 211, 0, 0.5) 100%);
  border-radius: 50%;
  animation: flicker 0.5s infinite ease-in-out alternate;
}

.flame-1 {
  animation-delay: 0s;
}

.flame-2 {
  animation-delay: 0.2s;
}

.flame-3 {
  animation-delay: 0.4s;
}

/* Keyframes */
@keyframes blastoff {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-800px);
  }
}

@keyframes flicker {
  0% {
    transform: scaleY(1);
    opacity: 1;
  }
  100% {
    transform: scaleY(0.7);
    opacity: 0.8;
  }
}

</style>
